import { Header, Spacer, Text } from '@truepill/react-capsule'
import { useHistory } from 'react-router-dom'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { TRANSFERS_PATH } from '../../../constants'
import { useContentfulTheme } from '../../../hooks'
import { useAnalytics } from '../../../hooks/analytics-context'
import { StyledModalConfirmation } from './styledComponents'

interface ModalConfirmationProps {
  isOpen: boolean
  isSubmitting: boolean
  state: string
  onDismiss: () => void
  submit: () => Promise<void>
}

const ModalConfirmation = ({ isOpen, isSubmitting, state, onDismiss, submit }: ModalConfirmationProps): React.ReactElement => {
  const { theme } = useContentfulTheme()
  const history = useHistory()
  const { trackButtonClickEvent } = useAnalytics()
  const handleSubmit = async () => {
    trackButtonClickEvent('transfer_out_modal_confirmation_submit', 'Confirm')
    await submit()
    history.push(TRANSFERS_PATH, { fromTransferOut: true })
  }

  return (
    <StyledModalConfirmation.ConfirmModal isOpen={isOpen} onDismiss={onDismiss}>
      <Header variant='4xl'>Confirm transfer</Header>
      <Spacer size='lg' />
      <StyledModalConfirmation.WarningText color={theme.colors['functional-warning-dark']} bold>
        This action cannot be undone
      </StyledModalConfirmation.WarningText>
      <Text>
        Once transferred, this prescription and all remaining refills will no longer be available for delivery through this service. You will need to
        contact your new pharmacy to request prescription refills.
      </Text>
      {state.toLowerCase() === 'ny' && (
        <Text>
          <strong>New York residents:</strong> Per state law, transferring your prescription will include only the current fill. A new prescription
          from your provider will be required for future refills.
        </Text>
      )}
      <Spacer size='xl' />

      <StyledModalConfirmation.ButtonContainerWrapper>
        <StyledModalConfirmation.ButtonContainer>
          <ThemedButton role='button' onClick={onDismiss} variant={'primary-text'} vpTheme={theme}>
            Cancel
          </ThemedButton>
          <ThemedButton role='button' disabled={isSubmitting} onClick={handleSubmit} variant={'primary'} vpTheme={theme}>
            Confirm
          </ThemedButton>
        </StyledModalConfirmation.ButtonContainer>
      </StyledModalConfirmation.ButtonContainerWrapper>
    </StyledModalConfirmation.ConfirmModal>
  )
}

export default ModalConfirmation
