import { Card, Grid, Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import { breakpoint, media } from 'common/styles/variables'
import styled from 'styled-components/macro'

import { Modal } from '../../../../common/styledComponents/StyledModal'
import { ThemedComponent } from '../../../../common/styledComponents/types'

const GroupedText = styled.div`
  display: inline-block;
`

const OrderConfirmationContainer = styled.div`
  line-height: 1.6em;
  max-width: 744px;
  margin-top: 2rem;
  @media only screen and (max-width: ${media.mobile}) {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
`
const OrderCompleteHeader = styled(Grid)``

const StyledModal = styled(Modal)`
  [data-cap-modal-close-button='true'] {
    top: 1.8rem;
  }
  [data-reach-dialog-content] {
    padding: 1.5rem;
  }
`

const OrderCancellationModal = styled(StyledModal)`
  > div:first-child {
    max-width: 800px;
  }
  [data-cap-modal-close-button='true'] {
    top: 1.8rem;
  }
`

const StyledSuccessModal = styled(Modal)`
  [data-cap-modal-close-button='true'] {
    top: 1.8rem;
  }
  [data-reach-dialog-content] {
    padding: 1.5rem;
  }
`

const OrderCancellationSuccessModal = styled(StyledSuccessModal)`
  > div:first-child {
    max-width: 687px;
  }
  [data-cap-modal-close-button='true'] {
    top: 1.8rem;
  }
`

const OrderCancellationFailureModal = styled(StyledSuccessModal)`
  > div:first-child {
    max-width: 687px;
  }
  [data-cap-modal-close-button='true'] {
    top: 1.8rem;
  }
`

const AutoRefillBanner = styled.div<ThemedComponent>`
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['primary-500'] ?? defaultTheme.colors['primary-500']};
`

const AutoRefillBannerHeader = styled.div`
  display: flex;
  align-items: center;
  > svg {
    width: 1rem;
    height: 1rem;
  }
`

const AutoRefillBannerText = styled(Text)<ThemedComponent>`
  font-size: 0.875rem;
  color: ${({ vpTheme }) => vpTheme.colors['typography-medium']};
`

const OrderSummaryInfo = styled.div`
  margin: 1rem 0;
`

const CardHeader = styled(Card)`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`

const CancelOrderContainer = styled(Text)<ThemedComponent>`
  font-size: 1rem;
  color: ${({ vpTheme }) => vpTheme.colors['primary-500']};
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
`

const CardBody = styled(Card)`
  border-radius: 0 0 8px 8px;
  border-top: none;
`

const OrderText = styled(Text)<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['primary-700']};
  background-color: ${({ vpTheme }) => vpTheme.colors['primary-100']};
  padding: 8px;
  border-radius: 100px;
  text-transform: uppercase;
  @media only screen and (max-width: ${media.mobile}) {
    text-align: center;
    white-space: nowrap;
    font-size: 0.8125rem;
  }
`

const MedicationSummaryContainer = styled.div``

const MedList = styled.div``

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const MedicationTotalContainer = styled.div``

const ShippingInfo = styled.div``

const PaymentInfo = styled.div``

const PaymentDetailText = styled(Text)`
  display: flex;
  align-items: center;
`

const CenteredText = styled(Text)`
  text-align: center;
`

const TransferCard = styled(Card)<ThemedComponent>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ vpTheme }) => vpTheme.colors['gray-300']};
  border-radius: 8px;
  ${breakpoint.tablet} {
    flex-direction: column;
    gap: 16px;
  }
`

const SuccessfulText = styled(CenteredText)`
  font-size: 1.25rem;
  line-height: 1.625rem;
  @media only screen and (max-width: ${media.mobile}) {
    font-size: 1.125rem;
    line-height: 1.25rem;
  }
`

const ConfirmationEmailText = styled(CenteredText)<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['typography-medium']};
  @media only screen and (max-width: ${media.mobile}) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`

export {
  AutoRefillBanner,
  AutoRefillBannerHeader,
  AutoRefillBannerText,
  CancelOrderContainer,
  CardBody,
  CardHeader,
  CenteredText,
  ConfirmationEmailText,
  GroupedText,
  ItemContainer,
  MedicationSummaryContainer,
  MedicationTotalContainer,
  MedList,
  OrderCancellationFailureModal,
  OrderCancellationModal,
  OrderCancellationSuccessModal,
  OrderCompleteHeader,
  OrderConfirmationContainer,
  OrderSummaryInfo,
  OrderText,
  PaymentDetailText,
  PaymentInfo,
  ShippingInfo,
  SuccessfulText,
  TransferCard,
}
