import { Header, SEO, Spacer, Text } from '@truepill/react-capsule'
import { unformatPhone } from '@vpharm-platform/shared'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { ClockIcon, InfoCircleIcon } from '../../assets/Icons'
import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { useLDContextWithLocalStorage } from '../../Components/LDProvider'
import { PrescriptionManagementLayout } from '../../Components/PageLayoutWithSidebar'
import StyledToastMessage from '../../Components/ToastMessage'
import { PRESCRIPTION_MANAGEMENT_PATH, PrescriptionManagementPage, retrieveNavigationLinks } from '../../constants'
import { ToastState } from '../../constants/toastConstants'
import { useCustomerProfile } from '../../hooks'
import { TransferOutPageMicrocopy } from '../../hooks/contentful/types/microcopy'
import { useGetPageContent } from '../../hooks/contentful/useGetPageContent'
import ModalConfirmation from './ModalConfirmation/ModalConfirmation'
import PharmacyFinder from './PharmacyFinder/PharmacyFinder'
import PrescriptionSelector from './PrescriptionSelector/PrescriptionSelector'
import { StyledTransferOut } from './styledComponents'
import { useTransferOut } from './useTransferOut'

const TransferOut = (): React.ReactElement => {
  const {
    isTransferOutReadyToSubmit,
    onPharmacySelect,
    isPharmacyEligibleForAutoTransfer,
    patientToken,
    customerToken,
    onPrescriptionSelect,
    selectedPharmacy,
    submitTransferOut,
    handleTransferOutModal,
    isConfirmationModalOpen,
    isSubmitting,
    errorMessage,
    handleTransferOutErrorTimeout,
    preselectedRxToken,
  } = useTransferOut()
  const history = useHistory()
  const { theme, content, seo } = useGetPageContent<TransferOutPageMicrocopy>('transferOutPage')
  const { customerProfile } = useCustomerProfile()
  const { autoRefills, transfersOut: transfersOutFFEnabled } = useLDContextWithLocalStorage()
  const someTransferTypesEnabled = customerProfile.allowPrescriptionTransfers || (customerProfile.transferOut && transfersOutFFEnabled)
  const isAutoTransferCurrentlyAllowed = isPharmacyEligibleForAutoTransfer || isPharmacyEligibleForAutoTransfer === null

  useEffect(() => {
    if (!customerProfile.transferOut || !transfersOutFFEnabled) {
      history.replace(PRESCRIPTION_MANAGEMENT_PATH)
    }
  }, [customerProfile.transferOut, history, transfersOutFFEnabled])

  const parsePharmacyTransferOutStatus = (isPharmacyEligibleForTransferOut: boolean | null): React.ReactElement | null => {
    if (isPharmacyEligibleForTransferOut === null) {
      return null
    }

    if (isPharmacyEligibleForAutoTransfer) {
      return (
        <StyledTransferOut.EligiblePharmacyMessage backgroundColor={theme.colors['functional-info-light']}>
          <InfoCircleIcon vpTheme={theme} />
          <Text>
            <Text bold>Confirm availability. &nbsp;</Text>
            To avoid any gaps in your treatment, we recommend contacting the pharmacy to confirm product availability.
            {selectedPharmacy?.phone && (
              <>
                {' '}
                This pharmacy can be reached at{' '}
                <StyledTransferOut.PhoneNumber color={theme.colors['functional-info-dark']} href={`tel:+${unformatPhone(selectedPharmacy.phone)}`}>
                  {selectedPharmacy.phone}
                </StyledTransferOut.PhoneNumber>
              </>
            )}
          </Text>
        </StyledTransferOut.EligiblePharmacyMessage>
      )
    }

    return (
      <StyledTransferOut.IneligiblePharmacyMessage
        borderColor={theme.colors['functional-warning-dark']}
        backgroundColor={theme.colors['functional-warning-light']}
      >
        <ClockIcon />
        <Text>
          <Text bold>Auto-transfer is unavailable for this pharmacy.&nbsp;</Text>
          You may manually request a transfer below, or remove and change your pharmacy selection above.
        </Text>
      </StyledTransferOut.IneligiblePharmacyMessage>
    )
  }

  return (
    <PrescriptionManagementLayout
      selected={PrescriptionManagementPage.PrescriptionTransfer}
      navigationLinks={retrieveNavigationLinks(customerProfile, someTransferTypesEnabled, autoRefills, theme)}
    >
      <SEO title={seo?.fields.title || `Transfer Out`} useDefaults />
      <Header bold variant='4xl'>
        Transfer prescriptions to in-store pickup
      </Header>
      <Spacer size='xs' />
      <Text>Transfer your current prescription to a different pharmacy for in-store pickup.</Text>
      <Spacer size='xl' />
      <StyledTransferOut.Container>
        <StyledTransferOut.StepHeaderContainer>
          <StyledTransferOut.StepNumber vpTheme={theme}>
            <StyledTransferOut.NumberText>1</StyledTransferOut.NumberText>
          </StyledTransferOut.StepNumber>
          <Header variant='2xl'>Select a pharmacy</Header>
        </StyledTransferOut.StepHeaderContainer>
        <Text>
          Search and select your preferred retail pharmacy. This will be the location where you plan to pick up your prescription in person.
        </Text>
        <Spacer size='md' />
        <Text bold>
          Pharmacy name: <StyledTransferOut.RequiredText color={theme.colors['functional-error-dark']}>*</StyledTransferOut.RequiredText>
        </Text>
        <Spacer size='sm' />
        <PharmacyFinder onPharmacySelect={onPharmacySelect} />
        {parsePharmacyTransferOutStatus(isPharmacyEligibleForAutoTransfer)}
        <Spacer size='lg' />
        <Spacer size='xl' />
        <Spacer size='xs' />
        <StyledTransferOut.PharamcyDivider $borderColor={theme.colors['gray-300']} size='2px' />
        <Spacer size='xs' />
        <Spacer size='xl' />
        <StyledTransferOut.StepHeaderContainer>
          <StyledTransferOut.StepNumber vpTheme={theme}>
            <StyledTransferOut.NumberText>2</StyledTransferOut.NumberText>
          </StyledTransferOut.StepNumber>
          <Header variant='2xl'>{isAutoTransferCurrentlyAllowed ? 'Choose a prescription to transfer' : 'Manually request a transfer'}</Header>
        </StyledTransferOut.StepHeaderContainer>
        {isAutoTransferCurrentlyAllowed ? (
          <Text>Select a prescription to transfer to the pharmacy you specified above.</Text>
        ) : (
          <>
            <Text>
              Please call your pharmacy{' '}
              {selectedPharmacy?.phone && (
                <>
                  at{' '}
                  <StyledTransferOut.PhoneNumber color={theme.colors['functional-info-dark']} href={`tel:+${unformatPhone(selectedPharmacy?.phone)}`}>
                    {selectedPharmacy.phone}
                  </StyledTransferOut.PhoneNumber>
                </>
              )}{' '}
              to request a prescription transfer. Provide the following pharmacy details, along with the name of the prescription(s) to transfer:
            </Text>
            <Spacer size='md' />

            <Text>{content?.pharmacyName}</Text>
            <Text>{content?.pharmacyAddress}</Text>
            <Spacer size='md' />

            {content?.pharmacyIds && (
              <StyledTransferOut.PharmacyRow>
                <Text bold>NCPDP:</Text>
                <Text>{content?.pharmacyIds}</Text>
              </StyledTransferOut.PharmacyRow>
            )}

            {content?.pharmacyFaxNumber && (
              <StyledTransferOut.PharmacyRow>
                <Text bold>Fax:</Text>
                <Text>{content?.pharmacyFaxNumber}</Text>
              </StyledTransferOut.PharmacyRow>
            )}
          </>
        )}
        <Spacer size='md' />
        <Text bold>
          Prescription information: <StyledTransferOut.RequiredText color={theme.colors['functional-error-dark']}>*</StyledTransferOut.RequiredText>
        </Text>
        <Spacer size='sm' />
        <PrescriptionSelector
          theme={theme}
          onPrescriptionSelect={onPrescriptionSelect}
          patientToken={patientToken}
          customerToken={customerToken}
          preSelectedToken={preselectedRxToken}
        />
      </StyledTransferOut.Container>
      <Spacer size='xl' />

      {isAutoTransferCurrentlyAllowed && (
        <StyledTransferOut.ButtonContainer>
          <ThemedButton
            variant='primary'
            role='button'
            onClick={() => handleTransferOutModal(true)}
            disabled={!isTransferOutReadyToSubmit}
            vpTheme={theme}
          >
            Transfer prescription
          </ThemedButton>
        </StyledTransferOut.ButtonContainer>
      )}

      {isConfirmationModalOpen && (
        <ModalConfirmation
          isOpen={isConfirmationModalOpen}
          isSubmitting={isSubmitting}
          state={selectedPharmacy?.state ?? ''}
          submit={submitTransferOut}
          onDismiss={() => handleTransferOutModal(false)}
        />
      )}

      <StyledToastMessage
        visible={!!errorMessage}
        position={{ vertical: 'top', horizontal: 'center' }}
        onTimeout={handleTransferOutErrorTimeout}
        state={ToastState.ERROR}
        timeout={5000}
      >
        {errorMessage}
      </StyledToastMessage>
    </PrescriptionManagementLayout>
  )
}

export default TransferOut
