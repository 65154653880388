import { Header, SEO, Text } from '@truepill/react-capsule'
import { ToastType } from '@truepill/react-capsule/dist/components/toast/Toast'
import { PrescriptionAvailabilityResponse } from '@vpharm-platform/shared'
import LoadingAnimation from 'Components/LoadingAnimation'
import { LOADABLE_STATE } from 'constants/loadableState'
import { useContentfulTheme, useCustomerProfile } from 'hooks'
import { lazy, Suspense, useState } from 'react'
import { useRecoilStateLoadable } from 'recoil'
import { prescriptionsByAvailability } from 'recoil/atoms'

import CustomerCopyChange from '../../Components/CustomerCopyChange'
import { withEmailVerified } from '../../Components/Hoc/withEmailVerified'
import { useLDContextWithLocalStorage } from '../../Components/LDProvider'
import { PrescriptionManagementLayout } from '../../Components/PageLayoutWithSidebar'
import SupportLink from '../../Components/SupportLink'
import { PrescriptionManagementPage, retrieveNavigationLinks } from '../../constants'
import { ToastErrorAnalyticsEventType, useAnalytics } from '../../hooks/analytics-context'
import { RxManagerPageMicrocopy } from '../../hooks/contentful/types/microcopy'
import { useGetPageContent } from '../../hooks/contentful/useGetPageContent'
import RxManagerInfoBanner from './InfoBanner/RxManagerInfoBanner'
import { StyledPrescriptionManagement } from './styledComponents'

const EmptyPrescriptionManager = lazy(() => import('./EmptyPrescriptionManager'))
const PrescriptionsList = lazy(() => import('./PrescriptionsList'))
const ToastMessage = lazy(() => import('Components/ToastMessage'))

type ToastMessage = {
  message: string
  status: ToastType
  timeout: 30000 | 60000
  visible: boolean
}

const defaultMessage: ToastMessage = {
  message: '',
  status: 'success',
  timeout: 30000,
  visible: false,
}

const PrescriptionManagement = () => {
  const { customerProfile } = useCustomerProfile()
  const [prescriptionListLoadable] = useRecoilStateLoadable(prescriptionsByAvailability)
  const [showMessage, setShowMessage] = useState<ToastMessage>(defaultMessage)
  const { loading: loadingPrescriptionManagerContent, content, seo } = useGetPageContent<RxManagerPageMicrocopy>('prescriptionManagerPage')
  const { transfersOut: transfersOutFFEnabled, autoRefills } = useLDContextWithLocalStorage()
  const someTransferTypesEnabled = customerProfile.allowPrescriptionTransfers || (customerProfile.transferOut && transfersOutFFEnabled)
  const { trackErrorShownEvent } = useAnalytics()
  const { theme } = useContentfulTheme()

  const prescriptions = prescriptionListLoadable.contents as PrescriptionAvailabilityResponse
  const isLoading = prescriptionListLoadable.state === LOADABLE_STATE.LOADING || loadingPrescriptionManagerContent

  const prescriptionCount =
    prescriptions && prescriptions.availableToOrder && prescriptions.unavailableToOrder
      ? prescriptions.availableToOrder.length + prescriptions.unavailableToOrder.length
      : 0

  if (isLoading) {
    return <LoadingAnimation />
  }

  if (prescriptionListLoadable.state === LOADABLE_STATE.HAS_ERROR) {
    setShowMessage({
      message: '',
      status: 'error',
      timeout: 60000,
      visible: true,
    })
    trackErrorShownEvent(ToastErrorAnalyticsEventType.ToastError, 'prescription_management_page_load')
  }

  if (prescriptionCount === 0 && !(prescriptionListLoadable.state === LOADABLE_STATE.HAS_ERROR)) {
    return (
      <EmptyPrescriptionManager
        seo={seo}
        content={content}
        customerName={customerProfile.customerName}
        allowPrescriptionTransfers={customerProfile.allowPrescriptionTransfers}
        theme={theme}
      />
    )
  }

  const handleDismiss = () => {
    setShowMessage({ message: '', status: 'success', timeout: 30000, visible: false })
  }
  const SidebarBanner = content && <RxManagerInfoBanner {...content} maxWidth='300px' />

  return (
    <StyledPrescriptionManagement.Wrapper isLoading={isLoading}>
      {seo?.fields.title && <SEO title={seo.fields.title || customerProfile.customerName} description={seo.fields.description} useDefaults />}
      <PrescriptionManagementLayout
        sidebarContent={SidebarBanner}
        selected={PrescriptionManagementPage.CurrentMedications}
        navigationLinks={retrieveNavigationLinks(customerProfile, someTransferTypesEnabled, autoRefills, theme)}
      >
        <Header bold variant='4xl'>
          Current medications
        </Header>
        <StyledPrescriptionManagement.ContentWrapper>
          <Text variant='body'>
            {content?.prescriptionManagerDescription || 'Order your medications, manage refills, and stay up to date on upcoming prescriptions.'}
          </Text>
          <PrescriptionsList prescriptions={prescriptions} setShowMessage={setShowMessage} content={content} />
        </StyledPrescriptionManagement.ContentWrapper>
        {content && content.customerCopyChangeText && content.customerCopyChangeText.length > 0 ? (
          <CustomerCopyChange copyChangeText={content.customerCopyChangeText} />
        ) : null}
      </PrescriptionManagementLayout>
      {showMessage.visible ? (
        <Suspense fallback={null}>
          <ToastMessage
            borderLeft={true}
            color='pastel'
            className='toast-message'
            icon={true}
            onDismiss={handleDismiss}
            position={{ vertical: 'top', horizontal: 'center' }}
            state={showMessage.status}
            visible={showMessage.visible}
            timeout={showMessage.timeout}
            onTimeout={handleDismiss}
          >
            {showMessage.status === 'success' ? (
              <Text>{showMessage.message}</Text>
            ) : showMessage.message ? (
              showMessage.message
            ) : (
              <Text>
                The connection to our database has failed, please{' '}
                <StyledPrescriptionManagement.TextLink
                  href=''
                  onClick={() => {
                    window.location.reload()
                  }}
                >
                  refresh the page
                </StyledPrescriptionManagement.TextLink>{' '}
                and try again later or alternatively email <SupportLink style={{ color: theme.colors['functional-info-dark'] }} />.
              </Text>
            )}
          </ToastMessage>
        </Suspense>
      ) : null}
    </StyledPrescriptionManagement.Wrapper>
  )
}

export default withEmailVerified(PrescriptionManagement)
