import { Header, Text } from '@truepill/react-capsule'
import { VpTheme } from '@vpharm-platform/shared'

import { IconCoupon } from '../../../../assets/Icons'
import { StyledSavingsCardBanner } from './styledComponents'
import { useSavingsCardBanner } from './useSavingsCardBanner'

interface SavingsCardBannerProps {
  theme: VpTheme
}

const SavingsCardBanner = ({ theme }: SavingsCardBannerProps): React.ReactElement => {
  const { onBannerClick } = useSavingsCardBanner()

  return (
    <StyledSavingsCardBanner.Container role='banner' onClick={onBannerClick} vpTheme={theme}>
      <IconCoupon color={theme.colors['primary-500']} />
      <StyledSavingsCardBanner.TextContainer vpTheme={theme}>
        <Header variant='2xl'>
          Add your savings card on your <StyledSavingsCardBanner.Underline vpTheme={theme}>Prescription Manager</StyledSavingsCardBanner.Underline>
        </Header>
        <Text>Look for the “savings card available” label</Text>
      </StyledSavingsCardBanner.TextContainer>
    </StyledSavingsCardBanner.Container>
  )
}

export default SavingsCardBanner
