import { Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { ThemedComponent } from '../../../common/styledComponents/types'
import { breakpoint, mediaLargerThan } from '../../../common/styles/variables'

const SeparatorPipe = styled(Text)`
  ${breakpoint.tablet} {
    display: none;
  }
`

const BackLink = styled(Link)<{ color?: string }>`
  font-weight: 400;
  font-size: 1rem;
  color: ${({ color }) => color ?? defaultTheme.colors['typography-medium']};
  text-decoration: none;

  @media print {
    display: none;
  }
`

const Logo = styled.img<{ maxHeightMobile?: number; maxHeight?: number }>`
  max-height: ${(props) => props.maxHeightMobile || '30'}px;
  max-width: 280px;
  object-fit: contain;

  display: none;

  ${mediaLargerThan.mobile} {
    max-height: ${(props) => props.maxHeight || '30'}px;
  }

  @media print {
    display: block;
  }
`

const OrderDetailsContainer = styled.div`
  max-width: 46.5rem;
  width: 100%;
  padding: 0 1.5rem;
  flex-grow: 1;
`

const MedicationInfoContainer = styled.div`
  ${mediaLargerThan.tablet} {
    margin-top: 0;
    display: flex;
    justify-content: space-between;
  }
`

const OrderReceiptSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 1rem;
  ${breakpoint.tablet} {
    margin-top: 1rem;
  }
`

const PrintReceipt = styled(ThemedButton)`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  border-radius: 4px;
  padding: 0.25rem 1rem;
  gap: 0.5rem;
  height: 2rem;
  width: 8.75rem;
  white-space: nowrap;
  margin-bottom: 2rem;

  svg {
    flex-shrink: 0;
    path {
      stroke: ${({ vpTheme }) => vpTheme.colors['primary-700']};
    }
  }

  :hover {
    svg {
      path {
        stroke: ${defaultTheme.colors.white};
      }
    }
  }
`

const PrintReceiptText = styled.span`
  display: flex;
  gap: 0.25rem;
`

const OrderedOnSection = styled.div`
  display: flex;
  gap: 1rem;
  ${breakpoint.tablet} {
    flex-direction: column;
    gap: 0.5rem;
  }
`

const PharmacyDetailsSection = styled.div<{ color?: string }>`
  a {
    color: ${({ color }) => color ?? defaultTheme.colors['functional-info-dark']};
  }
`

const ShippingDetailsSection = styled.div``

const OrderBreakdownSection = styled.div``

const OrderBreakdownShippingName = styled.span<{ color?: string }>`
  color: ${({ color }) => color ?? defaultTheme.colors['typography-medium']};
`

const PaymentDetailsSection = styled.div``

const BorderBox = styled.div<ThemedComponent>`
  box-sizing: border-box;
  margin: 1rem 0;

  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300']};
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
`

const BoxNoBorder = styled(BorderBox)`
  border: none;
`

const TrackingNumberBorderBox = styled(BorderBox)`
  border: none;
  background: ${({ vpTheme }) => vpTheme.colors['primary-100']};
  width: 22.5rem;
  min-height: 5.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0.5rem 0 2rem 0;
  ${breakpoint.tablet} {
    width: auto;
    max-width: 22.5rem;
  }
`

const TrackingNumberText = styled.a<{ color?: string }>`
  color: ${({ color }) => color ?? defaultTheme.colors['functional-info-dark']};
`

const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  ${breakpoint.tablet} {
    align-items: center;
  }
`

const PriceContainerNoMargin = styled.div`
  display: flex;
  justify-content: space-between;
  ${breakpoint.tablet} {
    align-items: center;
  }
`

const MedicationDetails = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
  color: ${({ color }) => color ?? defaultTheme.colors['typography-medium']};

  ${mediaLargerThan.mobile} {
    margin-top: 0;
  }
`

const PatientInfoContainer = styled.div<{ color?: string }>`
  display: flex;
  gap: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${({ color }) => color ?? defaultTheme.colors['typography-medium']};
  ${breakpoint.tablet} {
    gap: 0.5rem;
  }
`

const MedicationPriceDetails = styled.div`
  display: flex;
  gap: 1rem;
  line-height: 1.5rem;
  justify-content: space-between;
  ${breakpoint.tablet} {
    gap: 1rem;
  }
`

const OrderId = styled(Text)<{ color: string }>`
  color: ${({ color }) => color ?? defaultTheme.colors['typography-medium']};
`

const ShippingMinMaxText = styled(Text)<{ color?: string }>`
  color: ${({ color }) => color ?? defaultTheme.colors['typography-medium']};
`

const CardDetailsSection = styled.div`
  display: flex;
  ${breakpoint.tablet} {
    gap: 0.5rem;
    width: 12.5rem;
    align-items: center;
  }
`

const CardDetails = styled(Text)`
  ${breakpoint.tablet} {
    width: 8rem;
  }
`

const CardIcon = styled.span`
  margin: 0.5rem;
`

const CancelWarning = styled(Text)<{ color?: string }>`
  margin-top: 2rem;
  margin-bottom: 4rem;
  text-align: center;
  a {
    color: ${({ color }) => color ?? defaultTheme.colors['functional-info-dark']};
  }

  @media print {
    display: none;
  }
`

const FilledOnText = styled(Text)<{ color: string }>`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${({ color }) => color ?? defaultTheme.colors['typography-medium']};
`

const MedicationPricingDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  margin-top: 1rem;
`

const TwoPriceContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`

export {
  BackLink,
  BorderBox,
  BoxNoBorder,
  CancelWarning,
  CardDetails,
  CardDetailsSection,
  CardIcon,
  FilledOnText,
  Logo,
  MedicationDetails,
  MedicationInfoContainer,
  MedicationPriceDetails,
  MedicationPricingDetails,
  OrderBreakdownSection,
  OrderBreakdownShippingName,
  OrderDetailsContainer,
  OrderedOnSection,
  OrderId,
  OrderReceiptSection,
  PatientInfoContainer,
  PaymentDetailsSection,
  PharmacyDetailsSection,
  PriceContainer,
  PriceContainerNoMargin,
  PrintReceipt,
  PrintReceiptText,
  SeparatorPipe,
  ShippingDetailsSection,
  ShippingMinMaxText,
  TrackingNumberBorderBox,
  TrackingNumberText,
  TwoPriceContainer,
}
