import { Text } from '@truepill/react-capsule'
import { defaultTheme, VpTheme } from '@vpharm-platform/shared'
import { CheckCircle, X as CloseCrossIcon } from 'react-feather'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { mediaLargerThan } from '../../common/styles/variables'

interface StyledLogoProps {
  maxHeight?: number
  maxHeightMobile?: number
}

type BannerActionProps = {
  fontColor: string
  backgroundColor?: string
  content?: string
  redirectUrl?: string
}

const StyledLogo = styled.img<StyledLogoProps>`
  max-height: ${(props) => props.maxHeightMobile || '30'}px;
  max-width: 280px;
  object-fit: contain;

  ${mediaLargerThan.mobile} {
    max-height: ${(props) => props.maxHeight || '30'}px;
  }
`

const StyledCloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`

const StyledMenuButton = styled(StyledCloseButton)`
  display: flex;
  color: inherit;
  margin-left: 1.875rem;

  ${mediaLargerThan.laptop} {
    display: none;
  }
`
interface StyledHeaderProps {
  vpTheme: VpTheme
  hasBoxShadow: boolean
}

const StyledHeader = styled.header<StyledHeaderProps>`
  color: ${({ vpTheme }) => vpTheme.colors['typography-dark']};
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  box-shadow: ${(props) => props.hasBoxShadow && '0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08)'};
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  z-index: 2;
  position: relative;

  ${mediaLargerThan.desktopSm} {
    padding: 0.75rem 0.67rem;
  }

  @media print {
    display: none;
  }
`

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
`

const StyledNavItem = styled.div`
  display: none;

  ${mediaLargerThan.laptop} {
    display: flex;
  }
`

const StyledLink = styled(Link)`
  display: none;
  ${mediaLargerThan.laptop} {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    line-height: 1.625rem;
    margin-left: 3rem;
    text-decoration: none;
    color: inherit;
  }
`

const SignedOutNavLinksContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

const SignedOutActionableContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.75rem;

  ${mediaLargerThan.tablet} {
    grid-column-gap: 3rem;
    margin-left: 3rem;
  }
`

const StyledCloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;
`

const StyledTitle = styled(Text)<{ color: string }>`
  padding-left: 1.75rem;
  color: ${({ color }) => color};
`

const StyledList = styled.ul<{ color?: string }>`
  display: flex;
  flex-direction: column;
`

const CartContainer = styled.div`
  background-color: red;
  position: fixed;
  top: 5rem;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 3;
  background-color: rgba(78, 93, 112, 0.8);
  ${mediaLargerThan.mobile} {
    left: unset;
    bottom: unset;
    right: 16rem;
    top: 6rem;
    background-color: unset;
  }
`

const CardContainer = styled.div<{ background?: string }>`
  background-color: ${({ background }) => background || defaultTheme.colors.white};
  max-width: 21.438rem;
  padding: 1.5rem;
  border-radius: 1rem;
  margin-top: 1rem;
  box-shadow: 0 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  ${mediaLargerThan.mobile} {
    margin-top: 0;
  }
`

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const CardHeaderTitle = styled.div`
  display: flex;
  align-items: center;
`

const CheckCircleIcon = styled(CheckCircle)<{ color?: string }>`
  width: 0.833rem;
  height: 0.833rem;
  color: ${({ color }) => color ?? defaultTheme.colors['functional-success-dark']};
  margin-top: 0.125rem;
`

const CloseButton = styled.div`
  cursor: pointer;
`

const CloseButtonIcon = styled(CloseCrossIcon)<{ color?: string }>`
  width: 1.25rem;
  height: 1.25rem;
  color: ${({ color }) => color || defaultTheme.colors.black};
`

const Title = styled(Text)`
  padding-left: 0.5rem;
`

const CardBody = styled.div`
  margin-top: 1.25rem;
`

const MedName = styled(Text)`
  margin-bottom: 0.25rem;
`

const GrayBody = styled(Text)<{ color?: string }>`
  color: ${({ color }) => color || defaultTheme.colors['typography-medium']};
`

const Price = styled(Text)`
  margin-top: 0.25rem;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  > *:first-child {
    margin-right: 1rem;
  }
`

const ActionButton = styled(ThemedButton)`
  width: 8.719rem;
  padding: 0.75rem 1.4rem;
`

const StyledWrapper = styled.div`
  position: relative;
`

const StyledDropdown = styled.div<{ background: string }>`
  position: absolute;
  z-index: 10;
  right: 0.3125rem;
  width: 18.5rem;
  padding: 1.5rem 0;
  margin-top: 0.5rem;
  border-radius: 8px;
  background: ${({ background }) => background};
  box-shadow: 0px 6px 8px -6px rgba(49, 49, 50, 0.6), 0px 8px 16px -6px rgba(49, 49, 50, 0.6);
`

const BannerContainer = styled.div<BannerActionProps>`
  background-color: ${(BannerActionProps) => BannerActionProps.backgroundColor};
  color: ${(BannerActionProps) => BannerActionProps.fontColor};
  padding: 1em;
  width: 100%;
  display: inline-flex;
  img {
    height: 1em;
    align-self: center;
    margin: 0 0.5em 0 0.5em;
  }
  a:link,
  a:visited,
  a:active {
    color: ${(props) => props.fontColor};
  }
  ${mediaLargerThan.desktopSm} {
    padding: 0.75rem 9.75rem;
  }
`

export {
  ActionButton,
  Actions,
  BannerContainer,
  CardBody,
  CardContainer,
  CardHeader,
  CardHeaderTitle,
  CartContainer,
  CheckCircleIcon,
  CloseButton,
  CloseButtonIcon,
  GrayBody,
  MedName,
  Price,
  SignedOutActionableContainer,
  SignedOutNavLinksContainer,
  StyledCloseButton,
  StyledCloseButtonWrapper,
  StyledDropdown,
  StyledHeader,
  StyledLink,
  StyledList,
  StyledLogo,
  StyledMenuButton,
  StyledNav,
  StyledNavItem,
  StyledTitle,
  StyledWrapper,
  Title,
}
