import { Text } from '@truepill/react-capsule'
import React, { PropsWithChildren } from 'react'

import { useContentfulTheme } from '../hooks'

interface LinkButtonProps {
  onClick: () => void
  dataTestId?: string
  color?: string
  fontSize?: string
}

export const LinkButton = ({ children, onClick, dataTestId, color, fontSize }: PropsWithChildren<LinkButtonProps>): React.ReactElement => {
  const { theme } = useContentfulTheme()
  return (
    <Text
      css={{
        alignItems: 'center',
        color: color || theme.colors['primary-500'],
        fontSize: fontSize ?? '0.875rem',
        textDecoration: 'underline',
        cursor: 'pointer',
        textAlign: 'center',
      }}
      onClick={onClick}
      role='link'
      tabIndex={0}
      data-testid={dataTestId}
    >
      {children}
    </Text>
  )
}
