import { Divider, GridItem, Header, Spacer, Text } from '@truepill/react-capsule'
import { CardType } from '@vpharm-platform/shared'
import { CreditCardBrandIcon } from 'assets/Icons'
import React, { useEffect, useState } from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { ArrowLoop } from '../../../../assets/Icons/ArrowLoop'
import { ThemedButton } from '../../../../common/styledComponents/ThemedButton'
import { LoadingAnimationWrapper } from '../../../../Components/Insurances/styledComponents'
import { LinkButton } from '../../../../Components/LinkButton'
import LoadingAnimation from '../../../../Components/LoadingAnimation'
import { PrescriptionCardMicrocopy } from '../../../../Components/MedicationCard/microcopy'
import SupportLink from '../../../../Components/SupportLink'
import { AUTO_REFILLS, AUTO_REFILLS_PATH, ORDERS_PATH, TRANSFERS_PATH, VP_5666_CANCEL_ORDER } from '../../../../constants'
import { INITIAL_CARD, useContentfulTheme, useCustomerProfile } from '../../../../hooks'
import { LinkOrigin, useAnalytics } from '../../../../hooks/analytics-context'
import { MicroCopyResourceType } from '../../../../hooks/contentful/types/microcopy'
import { useContentfulMicrocopy } from '../../../../hooks/contentful/useContentfulMicrocopy'
import { useLDFlagsWithLocalStorage } from '../../../../hooks/useLDFlagsWithLocalStorage'
import { selectedPatientTokenAtom } from '../../../../persistRecoil'
import { orderService } from '../../../../services'
import { formatArrivalDateLocal } from '../../../../utils/dateUtilities'
import { capitalizeFirstLetter } from '../../../../utils/stringUtilities'
import CancelOrderConfirmation from '../CancelOrder/CancelOrderConfirmation'
import CancelOrderFailure from '../CancelOrder/CancelOrderFailure'
import CancelOrderSuccess from '../CancelOrder/CancelOrderSuccess'
import { useOrderConfirmation } from './hooks/useOrderConfirmation'
import {
  AutoRefillBanner,
  AutoRefillBannerHeader,
  AutoRefillBannerText,
  CardBody,
  CardHeader,
  CenteredText,
  ConfirmationEmailText,
  GroupedText,
  ItemContainer,
  MedicationSummaryContainer,
  MedicationTotalContainer,
  MedList,
  OrderCancellationFailureModal,
  OrderCancellationModal,
  OrderCancellationSuccessModal,
  OrderCompleteHeader,
  OrderConfirmationContainer,
  OrderSummaryInfo,
  OrderText,
  PaymentDetailText,
  PaymentInfo,
  ShippingInfo,
  SuccessfulText,
  TransferCard,
} from './styledComponents'

function ItemText({ name, value }: { name: string; value: number }) {
  return (
    <>
      <ItemContainer>
        <Text>{name}</Text>
        <Text>{`$${value.toFixed(2)}`}</Text>
      </ItemContainer>
      <Spacer size='2xs' axis='vertical' />
    </>
  )
}

const formatPrescriptionDisplayName = (prescriptionGenericName: string | null, prescriptionBrandName: string | null) =>
  prescriptionGenericName ? `${prescriptionBrandName} (${prescriptionGenericName})` : prescriptionBrandName

const determinePriceToBold = (retailPrice: number | null, copayPrice: number | null, savingsPrice: number | null): 'retail' | 'copay' | 'savings' => {
  if (savingsPrice !== null) {
    return 'savings'
  }

  if (copayPrice !== null) {
    return 'copay'
  }

  return 'retail'
}

const OrderConfirmation = (): React.ReactElement => {
  const history = useHistory<{ prevPath: string }>()
  const { customerProfile } = useCustomerProfile()
  const { trackButtonClickEvent, trackLinkClickEvent } = useAnalytics()
  const { theme } = useContentfulTheme()
  const [cancelOrderLoading, setCancelOrderLoading] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [cancelOrderModalOpen, setCancelOrderModalOpen] = useState(false)
  const [cancelOrderSuccessModalOpen, setCancelOrderSuccessModalOpen] = useState(false)
  const [cancelOrderFailureModalOpen, setCancelOrderFailureModalOpen] = useState(false)
  const selectedPatientToken = useRecoilValue(selectedPatientTokenAtom)
  const { shipmentInfo, orderDetails, patientInfo, paymentInfo, isLoading, clearCart, setSelectedPayment, dynamicContent, allowPriceTransparency } =
    useOrderConfirmation()
  const featureFlags = useLDFlagsWithLocalStorage([AUTO_REFILLS, VP_5666_CANCEL_ORDER])
  const cancelOrderEnabled = featureFlags[VP_5666_CANCEL_ORDER]

  const { microcopy: content, isLoadingContent } = useContentfulMicrocopy<PrescriptionCardMicrocopy>(MicroCopyResourceType.PrescriptionCard)
  const { estimatedArrivalTime, shipmentPrice } = shipmentInfo
  const arrivalDate = formatArrivalDateLocal(estimatedArrivalTime)
  const onRequestTransferClick = () => {
    trackButtonClickEvent('order_confirmation_request_transfer_button', 'Request a transfer')
    history.push(TRANSFERS_PATH)
  }
  const onAutoRefillLinkClick = () => {
    trackLinkClickEvent('Learn more', `direct user to ${AUTO_REFILLS_PATH}`, LinkOrigin.Body)
  }
  const isAutoRefillEligible = featureFlags.autoRefills && customerProfile.autoRefills && orderDetails.isAutoRefillEligible

  /*
    This effect ensures that the user cannot navigate directly to order confirmation without going through checkout.
  */
  useEffect(() => {
    setCancelOrderModalOpen(false)
    setRedirect(false)
    if (history.location.state?.prevPath !== '/checkout') {
      history.push('/prescription-management')
      return
    }
    clearCart()
    return () => setSelectedPayment(INITIAL_CARD)
  }, [clearCart, history, setSelectedPayment, setCancelOrderModalOpen])

  if (isLoading || isLoadingContent) {
    return <LoadingAnimation size='sm' />
  }
  if (redirect) {
    return <Redirect to={ORDERS_PATH} />
  }

  const handleSubmitCancelOrder = async (orderIdenifier: string) => {
    setCancelOrderModalOpen(false)
    setCancelOrderLoading(true)
    try {
      await orderService.cancelOrder({
        orderId: orderIdenifier,
        customerToken: customerProfile.vpharmCustomerToken,
        patientToken: selectedPatientToken,
      })
      setCancelOrderSuccessModalOpen(true)
    } catch {
      setCancelOrderLoading(false)
      setCancelOrderSuccessModalOpen(false)
      setCancelOrderFailureModalOpen(true)
    } finally {
      setCancelOrderLoading(false)
    }
  }

  const onCancelModalConfirmation = () => {
    setCancelOrderModalOpen(false)
  }

  const onCloseModalSuccessConfirmation = () => {
    setCancelOrderSuccessModalOpen(false)
    setRedirect(true)
  }

  const onCloseModalFailureConfirmation = () => {
    setCancelOrderFailureModalOpen(false)
  }

  return (
    <>
      <OrderConfirmationContainer>
        <OrderCompleteHeader justifyItems='center' justifyContent='center'>
          <GridItem mobile={4} desktop={12}>
            <Header bold variant='4xl'>{`Thank you, ${patientInfo.firstName}!`}</Header>
          </GridItem>
          <GridItem mobile={4} desktop={12}>
            <OrderText bold vpTheme={theme}>{`ORDER #${orderDetails.orderToken}`}</OrderText>
          </GridItem>
          <GridItem mobile={4} desktop={12}>
            <SuccessfulText>Your order has been processed successfully.</SuccessfulText>
          </GridItem>
          <GridItem mobile={4} desktop={12}>
            {cancelOrderEnabled ? (
              <>
                <ConfirmationEmailText vpTheme={theme}>
                  {
                    "You'll get a confirmation email shortly. A receipt will be available once the medication has been dispensed, typically within 24-48 hours. Need to cancel your order?"
                  }
                </ConfirmationEmailText>
                <LinkButton
                  fontSize='1rem'
                  color={theme.colors['functional-info-dark']}
                  onClick={() => {
                    setCancelOrderModalOpen(true)
                  }}
                >
                  Request a cancelation
                </LinkButton>
              </>
            ) : (
              <>
                <ConfirmationEmailText vpTheme={theme}>
                  {
                    "You'll get a confirmation email shortly. A receipt will be available once the medication has been dispensed, typically within 24-48 hours."
                  }
                </ConfirmationEmailText>
              </>
            )}
          </GridItem>
        </OrderCompleteHeader>
        <Spacer />
        {cancelOrderModalOpen && (
          <OrderCancellationModal
            isOpen={cancelOrderModalOpen}
            onDismiss={() => {
              setCancelOrderModalOpen(false)
            }}
            aria-label={'Cancel order modal'}
          >
            <CancelOrderConfirmation
              cancel={onCancelModalConfirmation}
              orderIdentifier={orderDetails.orderToken ?? null}
              handleSubmitCancelOrder={handleSubmitCancelOrder}
            />
          </OrderCancellationModal>
        )}
        {cancelOrderLoading && (
          <LoadingAnimationWrapper vpTheme={theme}>
            <LoadingAnimation />
          </LoadingAnimationWrapper>
        )}
        {cancelOrderSuccessModalOpen && (
          <OrderCancellationSuccessModal
            isOpen={cancelOrderSuccessModalOpen}
            onDismiss={onCloseModalSuccessConfirmation}
            aria-label={'Cancel order success modal'}
          >
            <CancelOrderSuccess cancel={onCloseModalSuccessConfirmation} />
          </OrderCancellationSuccessModal>
        )}
        {cancelOrderFailureModalOpen && (
          <OrderCancellationFailureModal
            isOpen={cancelOrderFailureModalOpen}
            onDismiss={() => setCancelOrderFailureModalOpen(false)}
            aria-label={'Cancel order failure modal'}
          >
            <CancelOrderFailure cancel={onCloseModalFailureConfirmation} />
          </OrderCancellationFailureModal>
        )}
        {isAutoRefillEligible && (
          <>
            <Spacer />
            <AutoRefillBanner vpTheme={theme}>
              <AutoRefillBannerHeader>
                <ArrowLoop vpTheme={theme} />
                <Spacer size='xs' />
                <Text bold>Auto refill now available!</Text>
              </AutoRefillBannerHeader>
              <AutoRefillBannerText vpTheme={theme}>
                Enroll now to get your prescription refills delivered automatically.{' '}
                <Link to={AUTO_REFILLS_PATH} onClick={onAutoRefillLinkClick}>
                  Learn more.
                </Link>
              </AutoRefillBannerText>
            </AutoRefillBanner>
            <Spacer />
          </>
        )}
        <OrderSummaryInfo>
          <CardHeader width='auto' shadow='none' css={{ borderRadius: '8px 8px 0 0', backgroundColor: theme.colors['gray-300'] }}>
            <Header bold variant='2xl'>
              {'Order summary'}
            </Header>
          </CardHeader>
          <CardBody
            color='white'
            width='auto'
            shadow='none'
            css={{ borderWidth: '1px', borderStyle: 'solid', borderColor: theme.colors['gray-300'] }}
          >
            <Spacer size='md' axis='vertical' />
            {!allowPriceTransparency && <Text bold>Medication</Text>}
            <Spacer size='2xs' axis='vertical' />
            <MedicationSummaryContainer>
              <MedList role='list' aria-label='medications'>
                {orderDetails.medications.map((med) => {
                  const retailPriceLineThrough =
                    (med.copayPrice && med.copayPrice > 0) || (med.priceWithSavings && med.priceWithSavings > 0) ? 'line-through' : 'none'
                  const insuranceLineThrough =
                    med.copayPrice && med.copayPrice > 0 && med.priceWithSavings && med.priceWithSavings > 0 ? 'line-through' : 'none'
                  if (allowPriceTransparency) {
                    const priceTagToBold = determinePriceToBold(med.retailPrice, med.copayPrice, med.priceWithSavings)
                    return (
                      <div role='listitem' key={med.rxNumber}>
                        <ItemContainer>
                          <Text bold>{`${formatPrescriptionDisplayName(med.genericDisplayName, med.brandDisplayName)}`}</Text>
                        </ItemContainer>
                        {med.retailPrice !== null && (
                          <ItemContainer>
                            <Text>Retail price</Text>
                            <Text bold={priceTagToBold === 'retail'} style={{ textDecoration: `${retailPriceLineThrough}` }}>
                              ${med.retailPrice.toFixed(2)}
                            </Text>
                          </ItemContainer>
                        )}
                        {med.copayPrice !== null && (
                          <ItemContainer>
                            <Text>{content?.priceAfterInsurance}</Text>
                            <Text bold={priceTagToBold === 'copay'} style={{ textDecoration: `${insuranceLineThrough}` }}>
                              ${med.copayPrice.toFixed(2)}
                            </Text>
                          </ItemContainer>
                        )}
                        {med.priceWithSavings !== null && (
                          <ItemContainer>
                            <Text>{dynamicContent?.savingsPriceText || 'Savings price'}</Text>
                            <Text bold={priceTagToBold === 'savings'}>${med.priceWithSavings.toFixed(2)}</Text>
                          </ItemContainer>
                        )}
                        {med.savingsAmount !== null && med.savingsAmount > 0 && (
                          <ItemContainer>
                            <Text style={{ color: `${theme.colors['functional-success-dark']}` }}>{content?.totalSavingsText}</Text>
                            <Text style={{ color: `${theme.colors['functional-success-dark']}` }}>${med.savingsAmount.toFixed(2)}</Text>
                          </ItemContainer>
                        )}
                        <Spacer size='2xs' axis='vertical' />
                      </div>
                    )
                  }
                  const priceToShow = med.copayAmount || 0
                  return (
                    <div role='listitem' key={med.rxNumber}>
                      <ItemContainer>
                        <Text>{`${formatPrescriptionDisplayName(med.genericDisplayName, med.brandDisplayName)}`}</Text>
                        <Text bold>{`$${priceToShow.toFixed(2)}`}</Text>
                      </ItemContainer>
                      <Spacer size='2xs' axis='vertical' />
                    </div>
                  )
                })}
              </MedList>
              <Spacer size='lg' axis='vertical' />
            </MedicationSummaryContainer>
            <Divider variant='mid' css={{ borderColor: theme.colors['gray-300'] }} />
            <Spacer size='lg' axis='vertical' />
            <MedicationTotalContainer>
              <ItemContainer>
                <Text bold>{'Subtotal'}</Text>
                {orderDetails.subtotal !== null && <Text bold>{`$${orderDetails.subtotal.toFixed(2)}`}</Text>}
              </ItemContainer>
              <Spacer size='2xs' axis='vertical' />
              <ItemText name='Shipping' value={shipmentPrice} />
              {allowPriceTransparency && <ItemText name='Processing fee' value={0} />}
              {orderDetails.taxAmount !== null && <ItemText name='Tax' value={orderDetails.taxAmount} />}

              <ItemContainer>
                <Text bold>{'Order total'}</Text>
                {orderDetails.total !== null && <Text bold>{`$${orderDetails.total.toFixed(2)}`}</Text>}
              </ItemContainer>
              <Spacer size='lg' axis='vertical' />
            </MedicationTotalContainer>
            <Divider variant='mid' css={{ borderColor: theme.colors['gray-300'] }} />
            <Spacer size='lg' axis='vertical' />
            <ShippingInfo>
              <Text bold>Shipping</Text>
              <Spacer size='2xs' axis='vertical' />
              <ItemContainer>
                <Text>Expected delivery date</Text>
                <Text css={{ textAlign: 'right' }}>{arrivalDate}</Text>
              </ItemContainer>
              <Spacer size='lg' axis='vertical' />
            </ShippingInfo>
            <Divider variant='mid' css={{ borderColor: theme.colors['gray-300'] }} />
            <Spacer size='lg' axis='vertical' />
            <PaymentInfo>
              <Text bold>Payment</Text>
              <ItemContainer>
                {!!paymentInfo.brand && !!paymentInfo.last4 && orderDetails.total !== 0 ? (
                  <PaymentDetailText>
                    <CreditCardBrandIcon cardBrand={paymentInfo.brand as CardType} />
                    &nbsp;{`${capitalizeFirstLetter(paymentInfo.brand)} ending in ${paymentInfo.last4}`}
                  </PaymentDetailText>
                ) : (
                  <Text>Not required</Text>
                )}
                {orderDetails.total !== null && <Text>{`$${orderDetails.total.toFixed(2)}`}</Text>}
              </ItemContainer>
            </PaymentInfo>
            <Spacer size='lg' axis='vertical' />
          </CardBody>
          <Spacer size='lg' axis='vertical' />
          {customerProfile.allowPrescriptionTransfers && featureFlags.prescriptionTransfersV2 && (
            <>
              <TransferCard color='white' width='auto' shadow='none' vpTheme={theme}>
                <CenteredText>Want to send your other prescriptions here too?</CenteredText>
                <ThemedButton role='button' onClick={onRequestTransferClick} vpTheme={theme}>
                  Request a transfer
                </ThemedButton>
              </TransferCard>
              <Spacer size='lg' axis='vertical' />
            </>
          )}
          {/* TODO: Implement the link when the Order Details page is available */}
          {/* <CenteredText underline>View order details</CenteredText>
          <Spacer size='lg' axis='vertical' /> */}
          <CenteredText>
            {"Thanks for trusting Truepill as your pharmacy. We love feedback, so you're always welcome to "}
            <GroupedText>
              <SupportLink>contact Patient Support</SupportLink>
            </GroupedText>
            {' with any concerns or celebrations.'}
          </CenteredText>
          <Spacer size='lg' axis='vertical' />
        </OrderSummaryInfo>
      </OrderConfirmationContainer>
    </>
  )
}

export default OrderConfirmation
