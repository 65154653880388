import { Elements } from '@stripe/react-stripe-js'
import { BannerAlert, Header, SEO, Spacer, Text } from '@truepill/react-capsule'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { PillsIcon } from '../../assets/Icons'
import AddressForm from '../../Components/Addresses/AddressForm'
import { ModalContainer } from '../../Components/Addresses/styledComponents'
import { DefaultAddressModal } from '../../Components/AutoRefill/DefaultAddressModal'
import { DefaultPaymentModal } from '../../Components/AutoRefill/DefaultPaymentModal'
import { useLDContextWithLocalStorage } from '../../Components/LDProvider'
import LoadingAnimation from '../../Components/LoadingAnimation'
import { PrescriptionManagementLayout } from '../../Components/PageLayoutWithSidebar'
import PaymentForm from '../../Components/Payments/PaymentForm'
import RichTextRenderer from '../../Components/RichTextRenderer'
import StyledToastMessage from '../../Components/ToastMessage'
import { FAQ_PATH, PRESCRIPTION_MANAGEMENT_PATH, PrescriptionManagementPage, retrieveNavigationLinks } from '../../constants'
import { ToastState } from '../../constants/toastConstants'
import { useAnalytics } from '../../hooks/analytics-context'
import { useUserAddresses } from '../../hooks/useUserAddresses'
import useUserPayments, { CreatePaymentData, Payment } from '../../hooks/useUserPayments'
import { PatientAddress } from '../../interfaces'
import { initializeStripe } from '../../services/StripeService'
import { capitalizeFirstLetter } from '../../utils/stringUtilities'
import { BannerMessage } from '../AccountManagement/Addresses'
import AutoRefillCard, { AutoRefillCardMedInfo } from './AutoRefillCard'
import { StyledAutoRefill } from './styledComponents'
import { useAutoRefillPage } from './useAutoRefillPage'

const AutoRefillPageContent = (): React.ReactElement => {
  const history = useHistory()
  const { trackButtonClickEvent } = useAnalytics()
  const { autoRefills, autoRefillInsurance, transfersOut: transfersOutFFEnabled } = useLDContextWithLocalStorage()
  const [openAddAddressModal, setOpenAddAddressModal] = useState(false)
  const [openChangeAddressModal, setOpenChangeAddressModal] = useState(false)
  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false)
  const [openChangePaymentModal, setOpenChangePaymentModal] = useState(false)
  const [bannerAlert, setBannerAlert] = useState<BannerMessage | null>(null)
  const {
    customerProfile,
    autoRefillMeds,
    error,
    isLoading,
    setError,
    autoRefillMicrocopy,
    theme,
    seo,
    submitEnrollAutoRefill,
    submitDisenrollAutoRefill,
    isCashOnly,
  } = useAutoRefillPage()
  const someTransferTypesEnabled = customerProfile.allowPrescriptionTransfers || (customerProfile.transferOut && transfersOutFFEnabled)
  const { addressList, isLoading: isLoadingAddresses, addNewAddress, updateDefaultAddress } = useUserAddresses()
  const { paymentMethods, isLoading: isLoadingPayments, addPaymentMethod, setDefaultPaymentMethod } = useUserPayments()
  const defaultAddress = addressList.find((address) => address.isDefault)
  const defaultPayment = paymentMethods.find((payment) => payment.default)

  useEffect(() => {
    if (!customerProfile.autoRefills || !autoRefills) {
      history.replace(PRESCRIPTION_MANAGEMENT_PATH)
    }
  }, [customerProfile.autoRefills, autoRefills, history])

  const handleAddAddress = async (address: PatientAddress): Promise<{ error?: string }> => {
    trackButtonClickEvent('submit_add_address', 'Add delivery address', 'adds default address for auto-refill')
    const result = await addNewAddress(address)
    if (result.data) {
      setOpenAddAddressModal(false)
      setBannerAlert({ message: 'Your delivery address has been successfully added', type: 'success' })
    }
    return result
  }

  const handleSetDefaultAddress = async (address?: PatientAddress) => {
    if (!address) {
      return
    }
    setOpenChangeAddressModal(false)
    trackButtonClickEvent('set_default_address', 'Change default address', 'sets address as default for auto-refill')
    const result = await updateDefaultAddress(address)
    if (result.error) {
      setBannerAlert({ message: 'Something went wrong updating your default delivery address, please try again', type: 'error' })
    }
  }

  const handleAddPayment = async (data: CreatePaymentData) => {
    trackButtonClickEvent('save_add_payment', 'Save', 'adds default payment for auto-refill')
    const paymentMethod = await addPaymentMethod(data)
    if (paymentMethod) {
      setOpenAddPaymentModal(false)
      setBannerAlert({ message: 'Your payment method has been successfully added', type: 'success' })
    }
    return paymentMethod
  }

  const handleSetDefaultPayment = async (payment?: Payment) => {
    if (!payment) {
      return
    }
    setOpenChangePaymentModal(false)
    trackButtonClickEvent('set_default_payment', 'Change default payment', 'sets payment as default for auto-refill')
    const isSuccess = await setDefaultPaymentMethod(payment.paymentMethodId)
    if (!isSuccess) {
      setBannerAlert({ message: 'Something went wrong updating your default payment method, please try again', type: 'error' })
    }
  }

  if (isLoading || isLoadingAddresses || isLoadingPayments) {
    return <LoadingAnimation />
  }

  return (
    <PrescriptionManagementLayout
      selected={PrescriptionManagementPage.AutoRefills}
      navigationLinks={retrieveNavigationLinks(customerProfile, someTransferTypesEnabled, autoRefills, theme)}
    >
      <SEO title={seo?.fields.title || `Auto Refill`} useDefaults></SEO>
      <StyledAutoRefill.Container vpTheme={theme}>
        <Header bold variant='4xl'>
          Auto refill
        </Header>
        <Spacer size='md' />
        {autoRefills && autoRefillInsurance ? (
          !!autoRefillMicrocopy?.autoRefillHelpfulInfo && <RichTextRenderer document={autoRefillMicrocopy.autoRefillHelpfulInfo} />
        ) : (
          <>
            <Text>
              Enjoy the convenience of automatic prescription refills! Once you’ve ordered a prescription, it becomes eligible for auto refill as long
              as you have refills remaining. This feature is currently available for cash orders only. If your prescription is not listed below,{' '}
              <StyledAutoRefill.StyledLink vpTheme={theme} to={PRESCRIPTION_MANAGEMENT_PATH}>
                place an order
              </StyledAutoRefill.StyledLink>{' '}
              and follow the enrollment instructions on the order confirmation screen.
            </Text>
            <Spacer size='md' />
            <Text>
              For more information on auto refill, visit our{' '}
              <StyledAutoRefill.StyledLink vpTheme={theme} to={FAQ_PATH}>
                Help Center
              </StyledAutoRefill.StyledLink>
              .
            </Text>
          </>
        )}

        <Spacer size='xl' />
        <StyledAutoRefill.InfoContainer>
          <div>
            <Text>Default delivery address:</Text>
            <Text>
              {defaultAddress &&
                `${defaultAddress.address2 ? `${defaultAddress.address1} ${defaultAddress.address2}` : defaultAddress.address1}, ${
                  defaultAddress.city
                } ${defaultAddress.state}, ${defaultAddress.zip} `}
              <StyledAutoRefill.TextButton
                onClick={() => (addressList.length ? setOpenChangeAddressModal(true) : setOpenAddAddressModal(true))}
                vpTheme={theme}
                role='button'
                textColor={theme.colors['primary-500']}
                variant='primary-text'
              >
                {defaultAddress ? 'Change' : addressList.length ? 'Select delivery address' : 'Add delivery address'}
              </StyledAutoRefill.TextButton>
            </Text>
          </div>

          <div>
            <Text>Default payment method:</Text>
            <Text>
              {defaultPayment && `${capitalizeFirstLetter(defaultPayment.brand)} ${defaultPayment.last4} `}
              <StyledAutoRefill.TextButton
                onClick={() => (paymentMethods.length ? setOpenChangePaymentModal(true) : setOpenAddPaymentModal(true))}
                vpTheme={theme}
                role='button'
                textColor={theme.colors['primary-500']}
                variant='primary-text'
              >
                {defaultPayment ? 'Change' : paymentMethods.length ? 'Select payment method' : 'Add payment method'}
              </StyledAutoRefill.TextButton>
            </Text>
          </div>
        </StyledAutoRefill.InfoContainer>
        {autoRefillMeds.length > 0 ? (
          <>
            {(!defaultAddress || !defaultPayment) && (
              <>
                <Spacer size='2xl' />
                <BannerAlert state='info' style={{ padding: '1rem' }}>
                  <Text>
                    {!defaultAddress && !defaultPayment
                      ? 'To enable auto refill, a delivery address and payment method must be added.'
                      : !defaultAddress
                      ? 'To enable auto refill, a delivery address must be added.'
                      : 'To enable auto refill, a payment method must be added.'}
                  </Text>
                </BannerAlert>
              </>
            )}
            <Spacer size='xl' />
            <StyledAutoRefill.List>
              {autoRefillMeds.map((med) => {
                const medInfo: AutoRefillCardMedInfo = {
                  brandName: med.brandDisplayName,
                  genericName: med.genericDisplayName,
                  rxNumber: med.rxNumber,
                  medStrength: med.medicationStrength,
                  daysSupply: med.daysSupply,
                  medPrice: med.price,
                  nextFillDate: med.nextRefillDate,
                  refillsRemaining: med.refillsRemaining,
                  isAutoRefillOn: med.isAutoRefillEnabled,
                  form: med.form,
                  ndc: med.ndc,
                }

                return (
                  <AutoRefillCard
                    key={med.prescriptionToken}
                    submitEnrollAutoRefill={submitEnrollAutoRefill}
                    submitDisenrollAutoRefill={submitDisenrollAutoRefill}
                    medInfo={medInfo}
                    isToggleDisabled={!defaultAddress || !defaultPayment}
                    prescriptionToken={med.prescriptionToken}
                    autoRefillMicrocopy={autoRefillMicrocopy}
                    isOnlyCashMode={isCashOnly}
                    theme={theme}
                  />
                )
              })}
            </StyledAutoRefill.List>
          </>
        ) : (
          <>
            <Spacer size='2xl' />
            <StyledAutoRefill.EmptyState vpTheme={theme}>
              <PillsIcon vpTheme={theme} colorOverride={theme.colors['typography-medium']} />
              <StyledAutoRefill.EmptyStateContent>
                <Text bold>You have no prescriptions eligible for auto refill</Text>
                <Text>Once available, all eligible prescriptions will be listed here</Text>
              </StyledAutoRefill.EmptyStateContent>
            </StyledAutoRefill.EmptyState>
            <Spacer size='lg' />
          </>
        )}
      </StyledAutoRefill.Container>

      <ModalContainer isOpen={openAddAddressModal} aria-label='Add New Address Modal' onDismiss={() => setOpenAddAddressModal(false)}>
        <Header variant='4xl' style={{ paddingRight: '2rem' }}>
          Add default delivery address
        </Header>
        <Spacer size='md' />
        <Text style={{ color: theme.colors['typography-medium'] }}>Set default delivery address to complete your auto refill set up.</Text>
        <Spacer size='lg' />
        <AddressForm submitButtonText='Add' onCancel={() => setOpenAddAddressModal(false)} onSubmit={handleAddAddress} />
      </ModalContainer>

      <ModalContainer isOpen={openChangeAddressModal} aria-label='Change Default Address Modal' onDismiss={() => setOpenChangeAddressModal(false)}>
        <DefaultAddressModal addressList={addressList} onSubmit={handleSetDefaultAddress} />
      </ModalContainer>

      <ModalContainer isOpen={openAddPaymentModal} aria-label='Add New Payment Modal' onDismiss={() => setOpenAddPaymentModal(false)}>
        <Header variant='4xl' style={{ paddingRight: '2rem' }}>
          Add default payment method
        </Header>
        <Spacer size='md' />
        <Text style={{ color: theme.colors['typography-medium'] }}>Set default payment method to complete your auto refill set up.</Text>
        <Spacer size='lg' />
        <PaymentForm onSubmit={handleAddPayment} onCancel={() => setOpenAddPaymentModal(false)} forceDefault />
      </ModalContainer>

      <ModalContainer isOpen={openChangePaymentModal} aria-label='Change Default Payment Modal' onDismiss={() => setOpenChangePaymentModal(false)}>
        <DefaultPaymentModal paymentMethods={paymentMethods} onSubmit={handleSetDefaultPayment} />
      </ModalContainer>

      <StyledToastMessage
        visible={!!error || !!bannerAlert}
        position={{ vertical: 'top', horizontal: 'center' }}
        onTimeout={() => {
          setError('')
          setBannerAlert(null)
        }}
        state={bannerAlert?.type ?? ToastState.ERROR}
        timeout={bannerAlert?.timeout ?? 5000}
      >
        {bannerAlert?.message ?? error}
      </StyledToastMessage>
    </PrescriptionManagementLayout>
  )
}

const AutoRefill: React.FunctionComponent = () => {
  const stripePromise = initializeStripe()
  return (
    <Elements options={{ fonts: [{ family: 'Lato', cssSrc: 'https://use.typekit.net/ysv7wrb.css' }] }} stripe={stripePromise}>
      <AutoRefillPageContent />
    </Elements>
  )
}

export default AutoRefill
