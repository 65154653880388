import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Divider, Header, Spacer } from '@truepill/react-capsule'
import { OrderDetails, OrderItemMedication, OrderRejectionInfo, OrderShippingInfo, OrderTriageInfo } from '@vpharm-platform/shared'
import { useEffect, useMemo, useState } from 'react'
import Scroll from 'react-scroll'

import { SlashIcon } from '../../../assets/Icons'
import { LoadingAnimationWrapper } from '../../../Components/Insurances/styledComponents'
import { LinkButton } from '../../../Components/LinkButton'
import LoadingAnimation from '../../../Components/LoadingAnimation'
import SupportLink from '../../../Components/SupportLink'
import { InfoTooltip } from '../../../Components/Tooltip'
import { DISPLAY_ORDER_TRIAGE_REJECTION_REASONS, VP_5666_CANCEL_ORDER } from '../../../constants'
import { useContentfulTheme } from '../../../hooks'
import { LinkOrigin, useAnalytics } from '../../../hooks/analytics-context'
import { useLDFlagsWithLocalStorage } from '../../../hooks/useLDFlagsWithLocalStorage'
import { orderService } from '../../../services'
import { formatArrivalDate } from '../../../utils/dateUtilities'
import CancelOrderConfirmation from '../../PrescriptionManagement/Checkout/CancelOrder/CancelOrderConfirmation'
import CancelOrderFailure from '../../PrescriptionManagement/Checkout/CancelOrder/CancelOrderFailure'
import CancelOrderSuccess from '../../PrescriptionManagement/Checkout/CancelOrder/CancelOrderSuccess'
import {
  OrderCancellationFailureModal,
  OrderCancellationModal,
  OrderCancellationSuccessModal,
} from '../../PrescriptionManagement/Checkout/OrderConfirmation/styledComponents'
import OrderMedication from './OrderMedication'
import OrderMessage from './OrderMessage'
import OrderReceiptStatus from './OrderReceiptStatus'
import {
  CancelOrderContainer,
  EtaHeader,
  IconContainer,
  OrderCardContainer,
  OrderCardHeader,
  OrderCardHeaderContainer,
  OrderCardMessage,
  OrderCardShippingContainer,
  OrderCardShippingETA,
  OrderCardShippingTracking,
  OrderDetailError,
  OrderMessageContainer,
  TrackingLink,
} from './styledComponents'
import { useRejectionAndTriageInfo } from './useRejectionAndTriageInfo'

interface Props {
  order_identifier: string
  createdAt: string
  items: OrderItemMedication[]
  shipping: OrderShippingInfo
  status: OrderDetails['status']
  patientName: string
  triageInfo?: OrderTriageInfo
  rejectionInfo?: OrderRejectionInfo
  patientToken?: string
  customerToken?: string
}

const OrderItem = ({
  order_identifier,
  createdAt,
  items,
  shipping,
  status,
  patientName,
  triageInfo,
  rejectionInfo,
  patientToken,
  customerToken,
}: Props): React.ReactElement => {
  const [shownMedicationItem, setShowMedicationItem] = useState<OrderItemMedication>()
  const [accordionItems, setAccordionItems] = useState<OrderItemMedication[]>()
  const [isToggled, setToggled] = useState<boolean>(false)
  const [toggleText, setToggleText] = useState<string>('Show full order')
  const [orderItemError, setOrderItemError] = useState<any | null>(null)
  const { trackLinkClickEvent, trackAccordionChangeEvent } = useAnalytics()
  const featureFlags = useLDFlagsWithLocalStorage([DISPLAY_ORDER_TRIAGE_REJECTION_REASONS])
  const { displayOrderTriageRejectionReasons, displayOrderRejectionReasons } = featureFlags
  const { theme } = useContentfulTheme()
  const cancelOrderEnabled = featureFlags[VP_5666_CANCEL_ORDER]
  const [cancelOrderLoading, setCancelOrderLoading] = useState(false)
  const [cancelOrderModalOpen, setCancelOrderModalOpen] = useState(false)
  const [cancelOrderSuccessModalOpen, setCancelOrderSuccessModalOpen] = useState(false)
  const [cancelOrderFailureModalOpen, setCancelOrderFailureModalOpen] = useState(false)

  const Element = Scroll.Element

  const { statusTag, statusMessage, issue, additionalDetails, richTextMessage } = useRejectionAndTriageInfo({ triageInfo, rejectionInfo, status })

  const shouldDisplayOrderMessage =
    ((status === 'PENDING' && displayOrderTriageRejectionReasons) || (status === 'FAILED' && displayOrderRejectionReasons)) && statusTag

  const etaHeaderInfo = useMemo(() => {
    const NOT_DELIVERED_STATUSES = ['REJECTED', 'FAILED']
    if (shipping?.status === 'DELIVERED' && !NOT_DELIVERED_STATUSES.includes(status)) {
      return {
        text: 'Delivered',
        date: formatArrivalDate(shipping.deliveredAt),
      }
    } else if (shipping?.eta && !NOT_DELIVERED_STATUSES.includes(status)) {
      return {
        text: 'Estimated delivery',
        date: formatArrivalDate(shipping.eta),
      }
    } else {
      return {
        text: 'Ordered on',
        date: formatArrivalDate(createdAt),
      }
    }
  }, [createdAt, shipping.deliveredAt, shipping.eta, shipping.status, status])

  const handleSubmitCancelOrder = async (orderIdenifier: string) => {
    setCancelOrderModalOpen(false)
    setCancelOrderLoading(true)
    try {
      await orderService.cancelOrder({
        orderId: orderIdenifier,
        customerToken: customerToken || '',
        patientToken: patientToken || '',
      })
      setCancelOrderSuccessModalOpen(true)
    } catch {
      setCancelOrderLoading(false)
      setCancelOrderSuccessModalOpen(false)
      setCancelOrderFailureModalOpen(true)
    } finally {
      setCancelOrderLoading(false)
    }
  }

  const onCancelModalConfirmation = () => {
    setCancelOrderModalOpen(false)
  }

  const onCloseModalSuccessConfirmation = () => {
    setCancelOrderSuccessModalOpen(false)
    window.location.reload()
  }

  const onCloseModalFailureConfirmation = () => {
    setCancelOrderFailureModalOpen(false)
  }

  useEffect(() => {
    const setItems = () => {
      if (items && items.length > 0) {
        setShowMedicationItem(items[0])
        if (items.length > 1) {
          setAccordionItems(items.slice(1))
        }
      } else {
        setOrderItemError(`Cannot retrieve order items for ${order_identifier ?? 'this order'}`)
      }
    }

    setItems()
  }, [items, order_identifier])

  useEffect(() => {
    const handleToggleText = () => {
      if (!isToggled) {
        setToggleText('Show full order')
      } else {
        setToggleText('Collapse order')
      }
    }
    handleToggleText()
  }, [isToggled])

  const extendedStatus: OrderDetails['status'] = useMemo(() => {
    if (displayOrderTriageRejectionReasons && status === 'PENDING' && triageInfo) {
      return 'TRIAGE'
    }
    return status
  }, [status, triageInfo, displayOrderTriageRejectionReasons])

  if (!items) {
    return (
      <Element name={order_identifier} id={order_identifier}>
        <OrderCardContainer data-testid='order-card' vpTheme={theme}>
          <OrderCardHeaderContainer data-testid='order-card-header-container' vpTheme={theme}>
            <OrderCardHeader data-testid='order-card-header' status={extendedStatus}>
              ORDER # {order_identifier}
              <OrderCardHeaderIcon status={extendedStatus} />
            </OrderCardHeader>
          </OrderCardHeaderContainer>
          <Spacer size='md' />
          <OrderDetailError data-testid='order-no-information'>There has been an issue retrieving the order items</OrderDetailError>
        </OrderCardContainer>
      </Element>
    )
  }

  return (
    <Element name={order_identifier}>
      <OrderCardContainer data-testid='order-card' id={order_identifier} vpTheme={theme}>
        <OrderCardHeaderContainer data-testid='order-card-header-container' vpTheme={theme}>
          <OrderCardMessage>
            <OrderCardHeader data-testid='order-card-header' status={extendedStatus}>
              ORDER # {order_identifier}
              <OrderCardHeaderIcon status={extendedStatus} />
            </OrderCardHeader>
            {shouldDisplayOrderMessage && (
              <OrderMessageContainer>
                <OrderMessage
                  text={statusMessage}
                  title={statusTag}
                  issue={issue}
                  additionalDetails={additionalDetails}
                  status={extendedStatus}
                  richTextMessage={richTextMessage}
                />
              </OrderMessageContainer>
            )}
          </OrderCardMessage>
          <OrderReceiptStatus
            status={status}
            orderId={order_identifier}
            onClick={() => trackLinkClickEvent('View Order Receipt', 'open order receipt page')}
          />
        </OrderCardHeaderContainer>
        {orderItemError && (
          <>
            <Spacer size='sm' />
            {orderItemError}
            <Spacer size='sm' />
          </>
        )}
        <OrderCardShippingContainer data-testid='order-card-shipping'>
          <OrderCardShippingETA data-testid='order-eta'>
            <Header variant='2xl'>
              <EtaHeader>
                <span>{etaHeaderInfo.text}</span>&nbsp;<span>{etaHeaderInfo.date}</span>
              </EtaHeader>
            </Header>
            {cancelOrderEnabled && status === 'PENDING' && (
              <CancelOrderContainer>
                <LinkButton
                  fontSize='1rem'
                  color={theme.colors['functional-info-dark']}
                  onClick={() => {
                    setCancelOrderModalOpen(true)
                  }}
                >
                  Request order cancelation
                </LinkButton>
              </CancelOrderContainer>
            )}

            {cancelOrderModalOpen && (
              <OrderCancellationModal
                isOpen={cancelOrderModalOpen}
                onDismiss={() => {
                  setCancelOrderModalOpen(false)
                }}
                aria-label={'Cancel order modal'}
              >
                <CancelOrderConfirmation
                  cancel={onCancelModalConfirmation}
                  orderIdentifier={order_identifier ?? null}
                  handleSubmitCancelOrder={handleSubmitCancelOrder}
                />
              </OrderCancellationModal>
            )}
            {cancelOrderLoading && (
              <LoadingAnimationWrapper vpTheme={theme}>
                <LoadingAnimation />
              </LoadingAnimationWrapper>
            )}
            {cancelOrderSuccessModalOpen && (
              <OrderCancellationSuccessModal
                isOpen={cancelOrderSuccessModalOpen}
                onDismiss={onCloseModalSuccessConfirmation}
                aria-label={'Cancel order success modal'}
              >
                <CancelOrderSuccess cancel={onCloseModalSuccessConfirmation} />
              </OrderCancellationSuccessModal>
            )}
            {cancelOrderFailureModalOpen && (
              <OrderCancellationFailureModal
                isOpen={cancelOrderFailureModalOpen}
                onDismiss={() => setCancelOrderFailureModalOpen(false)}
                aria-label={'Cancel order failure modal'}
              >
                <CancelOrderFailure cancel={onCloseModalFailureConfirmation} />
              </OrderCancellationFailureModal>
            )}
          </OrderCardShippingETA>
          <OrderCardShippingTracking data-testid='order-tracking'>
            {shipping.trackingUrl && (
              <TrackingLink
                href={shipping.trackingUrl}
                data-testid='order-card-tracking-nav'
                onClick={() => trackLinkClickEvent('Tracking #', 'sends user to tracking page', LinkOrigin.Body)}
                target='_blank'
                vpTheme={theme}
              >
                Tracking #{shipping.trackingNumber}
              </TrackingLink>
            )}
          </OrderCardShippingTracking>
        </OrderCardShippingContainer>
        <Spacer size='md' />
        <div data-testid='order-card-medications'>
          {shownMedicationItem && (
            <OrderMedication
              key={shownMedicationItem.prescriptionToken}
              days_supply={shownMedicationItem.daysSupply?.toString() ?? ''}
              name={
                shownMedicationItem.genericName
                  ? `${shownMedicationItem.brandName} (${shownMedicationItem.genericName})`
                  : shownMedicationItem.brandName
              }
              patient_name={patientName}
              quantity={shownMedicationItem.quantity}
              rx_number={shownMedicationItem.rxNumber}
              quantity_unit={shownMedicationItem.quantityUnit}
            />
          )}
          {accordionItems && accordionItems?.length > 0 && (
            <div data-testid='order-card-accordion-medications'>
              <Accordion type='multiple'>
                {accordionItems &&
                  accordionItems.map((item, index) => {
                    return (
                      <AccordionItem value={item.brandName} key={index}>
                        <AccordionContent>
                          <Divider
                            variant='mid'
                            css={{
                              borderColor: theme.colors['gray-300'],
                              width: 'stretch',
                              marginLeft: '16px',
                              marginRight: '16px',
                            }}
                          />
                          <Spacer size='md' />
                          <OrderMedication
                            key={'med_' + index}
                            name={item.brandName}
                            days_supply={item.daysSupply?.toString() || null}
                            patient_name={patientName}
                            quantity={item.quantity}
                            rx_number={item.rxNumber}
                            quantity_unit={item.quantityUnit}
                          />
                        </AccordionContent>{' '}
                        <AccordionTrigger
                          data-testid='show-order-details'
                          onClick={() => {
                            trackAccordionChangeEvent('show-order-details', toggleText, !isToggled)
                            setToggled(!isToggled)
                          }}
                          css={{ color: theme.colors['functional-info-dark'], fontWeight: 700, justifyContent: 'flex-start' }}
                        >
                          {toggleText}
                        </AccordionTrigger>
                      </AccordionItem>
                    )
                  })}
              </Accordion>
            </div>
          )}
        </div>
      </OrderCardContainer>
    </Element>
  )
}

const OrderCardHeaderIcon = ({ status }: { status: OrderDetails['status'] }) => {
  const { theme } = useContentfulTheme()
  const RECEIPT_PENDING_TEXT = 'Receipt pending'
  switch (status) {
    case 'TRIAGE':
      return (
        <IconContainer>
          <SlashIcon vpTheme={theme} />
        </IconContainer>
      )
    case 'REJECTED':
    case 'FAILED':
      return (
        <InfoTooltip
          label='Rejected Order'
          text={
            <>
              Unable to complete order. <SupportLink>Contact Patient Support</SupportLink> for more detail.
            </>
          }
          position='right'
          testId='rejected-order-tooltip'
          ariaLabel='Rejected Order'
          iconType='alert'
        />
      )
    case 'PENDING':
      return (
        <InfoTooltip
          label={RECEIPT_PENDING_TEXT}
          text={RECEIPT_PENDING_TEXT}
          position='right'
          testId='receipt-pending-tooltip'
          ariaLabel={RECEIPT_PENDING_TEXT}
        />
      )
    default:
      return <></>
  }
}

export default OrderItem
