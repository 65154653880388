import { Spacer } from '@truepill/react-capsule'
import { ReactElement, useCallback } from 'react'

import { ThemedButton } from '../../../../common/styledComponents/ThemedButton'
import { useContentfulTheme } from '../../../../hooks'
import { CancelOrderConfirmationText, StyledActionContainer, StyledHeader } from './styledComponent'

interface Props {
  cancel: () => void
  handleSubmitCancelOrder: (orderIdentifier: string) => void
  orderIdentifier: string | null
}

const CancelOrderConfirmation = ({ cancel, orderIdentifier, handleSubmitCancelOrder }: Props): ReactElement => {
  const { theme } = useContentfulTheme()

  const handleCancel = useCallback(() => {
    cancel()
  }, [cancel])

  const handleSubmitCancellation = useCallback(() => {
    handleSubmitCancelOrder(orderIdentifier as string)
  }, [handleSubmitCancelOrder, orderIdentifier])

  return (
    <>
      <StyledHeader variant='4xl'>Are you sure you want to cancel this order?</StyledHeader>
      <Spacer size='lg' />
      <CancelOrderConfirmationText vpTheme={theme}>Once processed, this order and all items within it will be cancelled.</CancelOrderConfirmationText>
      <Spacer size='2xl' />
      <StyledActionContainer>
        <ThemedButton data-testid='cancel-order-btn-cancel' variant='secondary-text' size='lg' onClick={handleCancel} vpTheme={theme}>
          Go back
        </ThemedButton>
        <ThemedButton danger={true} data-testid='cancel-order-btn-confirm' size='lg' onClick={handleSubmitCancellation} vpTheme={theme}>
          Cancel order
        </ThemedButton>
      </StyledActionContainer>
    </>
  )
}

export default CancelOrderConfirmation
